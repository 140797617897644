





















































































import Vue from "vue";
import BtnCreateOcupation from "@/views/Settings/buttons/BtnCreateOcupation.vue";
import BtnRemoveNomenclator from "../buttons/BtnRemoveNomenclator.vue";
import BtnEditNomenclator from "../buttons/BtnEditNomenclator.vue";
import { mapActions, mapState, mapMutations } from "vuex";
import { getAPI } from "@/api/axios-base";

export default Vue.extend({
  components: { BtnCreateOcupation, BtnRemoveNomenclator, BtnEditNomenclator },
  name: "ocupations",
  data() {
    return {
      search: "",
      seeCancels: false,
      entity: "getMedicalLocations",

      uuid: "",
      dialogConfirmRemove: false,
      headers: [
        { text: "Name", align: "start", value: "name" },
        { text: "", align: "end", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState("crmSettingsModule", ["loading", "nomenclators"]),
  },
  mounted() {
    this.actListNomenclator("Ocupation");
  },
  methods: {
    ...mapActions("crmSettingsModule", ["actListNomenclator"]),
    ...mapMutations("crmSettingsModule", ["mutNomencators"]),
    showRemoveDialog(uuid: string): void {
      this.uuid = uuid;
      this.dialogConfirmRemove = true;
    },

    async listOcupations() {
      const ocupation = (await getAPI(`/sprocedures/listNom/Ocupation`)).data;
      this.mutNomencators(ocupation);
    },
  },
});
